import React from 'react';
import Layout from 'components/Layout';
import { SliceZone, SignupForm, CustomerQuote } from 'components/Shared';
import { graphql } from 'gatsby';
import { getRelevantNode } from 'src/utils/helpers';
import { Row, Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Imgix from 'react-imgix';

const FormContainer = styled(Col)`
  background: ${({ theme }) => theme.palette.secondary.dark};
  margin: 0 auto;
  padding-top: 60px;

  ${media.md`
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

const StyledLayout = styled(Layout)`
  padding: 0 !important;
`;

const Illustration = styled.div`
  display: none;
  width: 500px;
  margin: 0 auto;

  ${media.md`
    display: block;
  `}
`;

const IllustrationCol = styled.div`
  width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
`;

const Mobile = styled.div`
  display: block;
  padding: 0 16px;

  ${media.sm`
    display: none;
  `}
`;

const StyledRow = styled(Row)`
  ${media.sm`
    width: 100%;
  `}
`;

const Image = styled(Imgix)`
  width: 100%;
  height: auto;
  object-fit: cover;
  text-align: start;
`;

const RegisterFormPage = ({ data, pageContext, location }) => {
  const {
    data: {
      body,
      meta_title,
      meta_description,
      meta_image,
      testimonial,
      image,
      ...rest
    },
    lang: pageLang,
    alternate_languages: altLangs,
  } = getRelevantNode(data.registerPage, pageContext.lang);

  return (
    <StyledLayout
      location={location}
      pageLang={pageLang}
      altLangs={altLangs}
      reducedHeader
      invertedHeader
      showFooter={false}
      flexMain
      seo={{
        title: meta_title,
        description: meta_description,
        image: meta_image?.url,
      }}
    >
      <StyledRow>
        <FormContainer md={5} sm={12}>
          <SignupForm data={rest} pageLang={pageLang} />
          {testimonial && (
            <Mobile>
              <CustomerQuote quote_data={testimonial.document.data} inverted />
            </Mobile>
          )}
        </FormContainer>
        <Col md={7} hiddenMdDown>
          <IllustrationCol>
            <Illustration>
              <Image
                className="lazyload"
                src={image.url}
                sizes="100vw"
                attributeConfig={{
                  src: 'data-src',
                  srcSet: 'data-srcset',
                  sizes: 'data-sizes',
                }}
              />
            </Illustration>
            <SliceZone allSlices={body} language={pageLang} />
            {testimonial && (
              <CustomerQuote quote_data={testimonial.document.data} small />
            )}
          </IllustrationCol>
        </Col>
      </StyledRow>
    </StyledLayout>
  );
};

export default RegisterFormPage;

export const query = graphql`
  query RegisterFormPageQuery($id: String!) {
    registerPage: allPrismicRegisterFormPage(filter: { id: { eq: $id } }) {
      nodes {
        lang
        alternate_languages {
          lang
          uid
        }
        data {
          meta_title
          meta_description
          meta_image {
            url
          }
          title {
            text
          }
          image {
            url
            alt
          }
          title_highlight {
            text
          }
          subtitle {
            text
          }
          show_email_field
          label_email {
            text
          }
          show_name_field
          label_first_name {
            text
          }
          label_last_name {
            text
          }
          show_company_field
          label_company {
            text
          }
          submit_label {
            text
          }
          success {
            text
          }
          success_highlight {
            text
          }
          back_link {
            url
          }
          back_link_label {
            text
          }
          appointment_button_text {
            text
          }
          success_highlight {
            text
          }
          privacy_notice {
            raw
            text
          }
          name_field_is_required
          hubspot_form_id {
            text
          }
          testimonial {
            document {
              ... on PrismicTestimonalQuote {
                id
                data {
                  person_image {
                    url
                    alt
                  }
                  quote {
                    text
                  }
                  person_name {
                    text
                  }
                  company_position {
                    text
                  }
                }
              }
            }
          }
          body {
            ... on PrismicRegisterFormPageBodyBrands {
              id
              slice_type
              items {
                title {
                  text
                }
                location {
                  text
                }
                image {
                  url
                }
                link {
                  url
                  link_type
                  target
                }
              }
              primary {
                small
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
